gsap.registerPlugin(ScrollTrigger);

const initServices = () => {
  const cards = document.querySelectorAll(".services-grid");

  const posts = document.querySelectorAll(".services-grid__item");

  cards.forEach((card) => {
    card.addEventListener("click", function () {
      this.querySelector(".services-card").classList.toggle("flipped");
    });
  });

  if (posts) {
    posts.forEach((span, i) => {
      gsap.from(span, {
        opacity: 0,
        y: "100px",
        delay: i * 0.2,
        scrollTrigger: {
          trigger: span,
          start: "top 90%",
          end: "bottom bottom",
          toggleActions: "play none none reverse",
          once: true,
        },
      });
    });
  }
};

document.addEventListener("DOMContentLoaded", function () {
  initServices();
});
